<template>
  <div class="title-bar">
    <div class="title-bar-left">
      <iconic class="title-bar-icon" v-if="icon" :name="icon" />
      <span class="title-bar-text">{{ title }}</span>
    </div>
    <div class="title-bar-right">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "icon"],
};
</script>

<style lang="scss" scoped>
.title-bar {
  @include Flex(inherit, space-between, center);
  width: 100%;
  gap: $mpadding;
  border-top: solid 1px $alto;
  border-bottom: solid 1px $alto;
  padding: 0 $mpadding;
  background-color: rgba(9, 30, 66, 0.01);
  &-left,
  &-right {
    display: flex;
    align-items: center;
    gap: $mpadding;
  }
  &-text {
    padding: $mpadding 0;
    font-weight: bold;
  }
  &-icon {
    @include Flex(inherit, center, center);
  }
}
</style>
